<template>
  <v-container v-if="!loading">
    <v-row>
      <v-col cols="12" md="9" lg="7" class="mx-auto text-center pa-0">
        <h3>My Favorite Dishes</h3>
      </v-col>
      <v-col cols="12" sm="1">
        <v-btn fab dark color="primary" @click.prevent="refresh">
          <v-icon dark>cached</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="list && list.length > 0">
      <v-col cols="12" sm="4" v-for="food in list" :key="food.id" class="mb-2">
        <food-view :dish="food"></food-view>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" sm="6" v-html="$t('Common.favorite_empty')"> </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import FoodView from '@/components/cook/public/FoodView'

export default {
  components: {
    FoodView
  },
  computed: {
    ...mapGetters({
      profile: 'profile',
      loading: 'loading'
    })
  },
  data() {
    return {
      list: []
    }
  },
  mounted() {
    this.$store.dispatch('loadProfile').then(profile => {
      this.loadFavorites()
    })
  },
  watch: {
    profile(val, prev) {
      if (prev == null && val != null) {
        this.loadFavorites()
      }
    }
  },
  methods: {
    refresh() {
      this.$router.go(this.$router.currentRoute)
    },
    loadFavorites() {
      if (this.profile && this.profile.favorite_dishes) {
        console.log('Loading favorite_dishes:', this.profile.favorite_dishes)
        this.$store
          .dispatch('loadFavorites', {
            list: this.profile.favorite_dishes,
            type: 'dish'
          })
          .then(list => {
            console.log('Loaded Favorite Dishes:', list)
            this.list = list
          })
      } else {
        console.log('favorite_dishes is empty')
      }
    }
  }
}
</script>
